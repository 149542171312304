.header {
  background-color: #ffffff !important; /* 白色に設定 */
  border-bottom: 1px solid #e0e0e0; /* グレーの薄い線を追加 */
}

.title {
  color: #000000; /* 黒色に設定 */
}

.kfOnePoint {
  height: 32px;
  width: auto;
  fill: gray;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 2px;
  margin-left: -12px;
}

.kfWordLogo {
  height: 28px;
  width: auto;
  fill: gray;
  padding-top: 12px;
  padding-bottom: 4px;
  padding-right: 12px;
  padding-left: 2px;
}

.logoContainer {
  display: flex;
  align-items: center;
  text-decoration: none; /* リンクの下線を消す */
}
