.maleIcon,
.femaleIcon {
  height: 48px !important;
  width: 48px !important;
  margin-right: 8px;
}

.calenderIcon {
  height: 20px !important;
  width: 20px !important;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pin {
  height: 20px !important;
  width: 20px !important;
  margin-left: 12px !important;
}
